import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { TextField, Checkbox, Link, Button, Container, Typography, Box, OutlinedInput, InputLabel, Radio, RadioGroup, FormControlLabel, FormControl, FormLabel, Select, MenuItem } from '@mui/material';

const VideoBriefForm = () => {
    const { t } = useTranslation();
    const [selectedVideoGoal, setSelectedVideoGoal] = useState('');
    const [selectedContentStyle, setSelectedContentStyle] = useState('');
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [isAgreed, setIsAgreed] = useState(false);

    const validationSchema = yup.object({
        companyName: yup.string(),
        contactPerson: yup.string().required(t('required')),
        contactType: yup.string().required(t('required')),
        videoGoal: yup.string().required(t('required')),
        videoGoalDescription: yup.string(),
        targetAudience: yup.string(),
        keyMessages: yup.string(),
        styleAndTone: yup.string().required(t('required')),
        styleAndToneDescription: yup.string(),
        videoLength: yup.string(),
        videoLanguage: yup.string(),
        needAnimation: yup.string(),
        musicPreferences: yup.string(),
        estimatedBudget: yup.number().typeError(t('numberRequired')).required(t('required')),
        projectTimeline: yup.string(),
        distributionChannels: yup.string(),
        videoExamples: yup.string(),
        additionalComments: yup.string(),
        isAgreed: yup.boolean()
            .required(t('required'))
            .oneOf([true], t('mustAgree')),
    });

    const formik = useFormik({
        initialValues: {
            companyName: '',
            contactPerson: '',
            contactType: '',
            videoGoal: 'productPromotion',
            videoGoalDescription: '',
            targetAudience: '',
            contentScenario: 'none',
            contentConcept: '',
            contentDescription: '',
            keyMessages: '',
            styleAndTone: 'formalCorporate',
            styleAndToneDescription: '',
            videoLength: '',
            videoLanguage: '',
            needAnimation: '',
            musicPreferences: '',
            estimatedBudget: 1000,
            projectTimeline: '',
            distributionChannels: '',
            videoExamples: '',
            additionalComments: '',
            isAgreed: false,
        },
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            try {
                const response = await fetch('/submit.php', {
                    method: 'POST',
                    body: JSON.stringify(values),
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });

                if (response.ok) {
                    setIsSubmitted(true);
                } else {
                    console.error('Server responded with non-OK status');
                }
            } catch (error) {
                console.error('Submission error', error);
            }
        },
    });

    const handleVideoGoalChange = (event) => {
        setSelectedVideoGoal(event.target.value);
        formik.handleChange(event);
    };

    const handleContentStyleChange = (event) => {
        setSelectedContentStyle(event.target.value);
        formik.handleChange(event);
    };

    const handleAgreementChange = (event) => {
        setIsAgreed(event.target.checked);
        formik.setFieldValue('isAgreed', event.target.checked);
    };

    return (
        <Container maxWidth="md">
            <Box mt={8} mb={6}>
                <Typography variant="h1" gutterBottom>
                    <Link href={"/"} underline="none" className={"logo"} color={"white"}>/=\</Link>
                </Typography>
            </Box>
            <Box mt={2} mb={6}>
                <Typography variant="h1" gutterBottom> {t('formTitle')}</Typography>
            </Box>
            <form onSubmit={formik.handleSubmit}>
                <Box mb={6}>
                    <Box mb={2}>
                        <Typography variant="h2">1. {t('section1Title')}</Typography>
                    </Box>
                    <Box mb={2}>
                        <InputLabel htmlFor="companyName">{t('companyName')}</InputLabel>
                    </Box>
                    <Box mb={3}>
                        <TextField
                            fullWidth
                            id="companyName"
                            name="companyName"
                            placeholder={t('companyNamePlaceholder')}
                            value={formik.values.companyName}
                            onChange={formik.handleChange}
                            error={formik.touched.companyName && Boolean(formik.errors.companyName)}
                            helperText={formik.touched.companyName && formik.errors.companyName}
                        />
                    </Box>
                    <Box mb={2}>
                        <InputLabel htmlFor="contactPerson">{t('contactPerson')} *</InputLabel>
                    </Box>
                    <Box mb={3}>
                        <TextField
                            fullWidth
                            id="contactPerson"
                            name="contactPerson"
                            label={t('contactPersonLabel')}
                            value={formik.values.contactPerson}
                            onChange={formik.handleChange}
                            error={formik.touched.contactPerson && Boolean(formik.errors.contactPerson)}
                            helperText={formik.touched.contactPerson && formik.errors.contactPerson}
                            required
                        />
                    </Box>
                    <Box mb={2}>
                        <InputLabel htmlFor="contactType">{t('contactType')} *</InputLabel>
                    </Box>
                    <Box mb={2}>
                        <TextField
                            fullWidth
                            id="contactType"
                            name="contactType"
                            label={t('contactTypeLabel')}
                            value={formik.values.contactType}
                            onChange={formik.handleChange}
                            error={formik.touched.contactType && Boolean(formik.errors.contactType)}
                            helperText={formik.touched.contactType && formik.errors.contactType}
                            required
                        />
                    </Box>
                </Box>
                <Box mb={6}>
                    <Box mb={2}>
                        <Typography variant="h2">2. {t('section2Title')}</Typography>
                    </Box>
                    <Box mb={2}>
                        <InputLabel htmlFor="videoGoal">{t('videoGoal')} *</InputLabel>
                    </Box>
                    <Box mb={3}>
                        <FormControl fullWidth>
                            <Select
                                id="videoGoal"
                                name="videoGoal"
                                value={formik.values.videoGoal}
                                onChange={handleVideoGoalChange}
                                error={formik.touched.videoGoal && Boolean(formik.errors.videoGoal)}
                            >
                                <MenuItem value="productPromotion">{t('productPromotion')}</MenuItem>
                                <MenuItem value="corporateVideo">{t('corporateVideo')}</MenuItem>
                                <MenuItem value="educational">{t('educational')}</MenuItem>
                                <MenuItem value="other">{t('other')}</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>
                    {selectedVideoGoal === 'other' && (
                        <Box mb={3}>
                            <Box mb={2}>
                                <InputLabel htmlFor="videoGoalDescription">{t('videoGoalDescription')}</InputLabel>
                            </Box>
                            <OutlinedInput
                                id="videoGoalDescription"
                                name="videoGoalDescription"
                                multiline
                                rows={3}
                                value={formik.values.videoGoalDescription}
                                onChange={formik.handleChange}
                                style={{ width: '100%' }}
                            />
                        </Box>
                    )}
                    <Box mb={2}>
                        <InputLabel htmlFor="targetAudience">{t('targetAudience')}</InputLabel>
                    </Box>
                    <Box mb={2}>
                        <OutlinedInput
                            id="targetAudience"
                            name="targetAudience"
                            placeholder={t('targetAudienceLabel')}
                            multiline
                            rows={3}
                            value={formik.values.targetAudience}
                            onChange={formik.handleChange}
                            style={{ width: '100%' }}
                        />
                    </Box>
                </Box>
                <Box mb={6}>
                    <Box mb={2}>
                        <Typography variant="h2">3. {t('section3Title')}</Typography>
                    </Box>
                    <Box mb={3}>
                        <FormControl component="fieldset">
                            <FormLabel component="legend">{t('contentScenarioQuestion')}</FormLabel>
                            <RadioGroup
                                id="contentScenario"
                                name="contentScenario"
                                value={formik.values.contentScenario}
                                onChange={formik.handleChange}
                            >
                                <FormControlLabel value="none" control={<Radio />} label={t('none')} />
                                <FormControlLabel value="script" control={<Radio />} label={t('script')} />
                                <FormControlLabel value="concept" control={<Radio />} label={t('concept')} />
                            </RadioGroup>
                        </FormControl>
                    </Box>
                    {(formik.values.contentScenario === 'script' || formik.values.contentScenario === 'concept') && (
                        <Box mb={3}>
                            <Box mb={2}>
                                <InputLabel htmlFor="contentDescription">{t('contentDescription')}</InputLabel>
                            </Box>

                            <OutlinedInput
                                id="contentDescription"
                                name="contentDescription"
                                multiline
                                rows={3}
                                value={formik.values.contentDescription}
                                onChange={formik.handleChange}
                                style={{ width: '100%' }}
                            />
                        </Box>
                    )}
                    <Box mb={2}>
                        <InputLabel htmlFor="keyMessages">{t('keyMessages')}</InputLabel>
                    </Box>
                    <Box mb={3}>
                        <TextField
                            fullWidth
                            id="keyMessages"
                            name="keyMessages"
                            value={formik.values.keyMessages}
                            onChange={formik.handleChange}
                            error={formik.touched.keyMessages && Boolean(formik.errors.keyMessages)}
                            helperText={formik.touched.keyMessages && formik.errors.keyMessages}
                        />
                    </Box>
                    <Box mb={2}>
                        <InputLabel htmlFor="styleAndTone">{t('styleAndTone')} *</InputLabel>
                    </Box>
                    <Box mb={3}>
                        <FormControl fullWidth>
                            <Select
                                id="styleAndTone"
                                name="styleAndTone"
                                value={formik.values.styleAndTone}
                                onChange={handleContentStyleChange}
                                error={formik.touched.styleAndTone && Boolean(formik.errors.styleAndTone)}
                            >
                                <MenuItem value="formalCorporate">{t('formalCorporate')}</MenuItem>
                                <MenuItem value="friendlyInformative">{t('friendlyInformative')}</MenuItem>
                                <MenuItem value="creativeInnovative">{t('creativeInnovative')}</MenuItem>
                                <MenuItem value="other">{t('other')}</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>
                    {selectedContentStyle === 'other' && (
                        <Box mb={3}>
                            <Box mb={2}>
                                <InputLabel htmlFor="styleAndToneDescription">{t('styleAndToneDescription')}</InputLabel>
                            </Box>
                            <OutlinedInput
                                id="styleAndToneDescription"
                                name="styleAndToneDescription"
                                multiline
                                rows={3}
                                value={formik.values.styleAndToneDescription}
                                onChange={formik.handleChange}
                                style={{ width: '100%' }}
                            />
                        </Box>
                    )}
                    <Box mb={2}>
                        <InputLabel htmlFor="videoExamples">{t('videoExamples')}</InputLabel>
                    </Box>
                    <Box mb={2}>
                        <OutlinedInput
                            id="videoExamples"
                            name="videoExamples"
                            placeholder={t('videoExamplesPlaceholder')}
                            multiline
                            rows={3}
                            value={formik.values.videoExamples}
                            onChange={formik.handleChange}
                            style={{ width: '100%' }}
                        />
                    </Box>
                </Box>
                <Box mb={6}>
                    <Box mb={2}>
                        <Typography variant="h2">4. {t('section4Title')}</Typography>
                    </Box>
                    <Box mb={2}>
                        <InputLabel htmlFor="videoLength">{t('videoLength')}</InputLabel>
                    </Box>
                    <Box mb={3}>
                        <TextField
                            fullWidth
                            id="videoLength"
                            name="videoLength"
                            placeholder={t('videoLengthPlaceholder')}
                            value={formik.values.videoLength}
                            onChange={formik.handleChange}
                        />
                    </Box>
                    <Box mb={2}>
                        <InputLabel htmlFor="videoLanguage">{t('videoLanguage')}</InputLabel>
                    </Box>
                    <Box mb={3}>
                        <TextField
                            fullWidth
                            id="videoLanguage"
                            name="videoLanguage"
                            placeholder={t('videoLanguagePlaceholder')}
                            value={formik.values.videoLanguage}
                            onChange={formik.handleChange}
                        />
                    </Box>
                    <Box mb={2}>
                        <InputLabel htmlFor="distributionChannels">{t('distributionChannels')}</InputLabel>
                    </Box>
                    <Box mb={3}>
                        <OutlinedInput
                            id="distributionChannels"
                            name="distributionChannels"
                            placeholder={t('distributionChannelsPlaceholder')}
                            multiline
                            rows={3}
                            value={formik.values.distributionChannels}
                            onChange={formik.handleChange}
                            style={{ width: '100%' }}
                        />
                    </Box>
                    <Box mb={2}>
                        <InputLabel htmlFor="needAnimation">{t('needAnimation')}</InputLabel>
                    </Box>
                    <Box mb={3}>
                        <TextField
                            fullWidth
                            id="needAnimation"
                            name="needAnimation"
                            value={formik.values.needAnimation}
                            onChange={formik.handleChange}
                        />
                    </Box>
                    <Box mb={2}>
                        <InputLabel htmlFor="musicPreferences">{t('musicPreferences')}</InputLabel>
                    </Box>
                    <Box mb={2}>
                        <TextField
                            fullWidth
                            id="musicPreferences"
                            name="musicPreferences"
                            value={formik.values.musicPreferences}
                            onChange={formik.handleChange}
                        />
                    </Box>
                </Box>
                <Box mb={6}>
                    <Box mb={2}>
                        <Typography variant="h2">5. {t('section5Title')}</Typography>
                    </Box>
                    <Box mb={2}>
                        <InputLabel htmlFor="estimatedBudget">{t('estimatedBudget')}</InputLabel>
                    </Box>
                    <Box mb={3}>
                        <TextField
                            fullWidth
                            id="estimatedBudget"
                            name="estimatedBudget"
                            label={t('estimatedBudgetCurrency')}
                            type="number"
                            value={formik.values.estimatedBudget}
                            onChange={formik.handleChange}
                            error={formik.touched.estimatedBudget && Boolean(formik.errors.estimatedBudget)}
                            helperText={formik.touched.estimatedBudget && formik.errors.estimatedBudget}
                            required
                        />
                    </Box>
                    <Box mb={2}>
                        <InputLabel htmlFor="projectTimeline">{t('projectTimeline')}</InputLabel>
                    </Box>
                    <Box mb={2}>
                        <TextField
                            fullWidth
                            id="projectTimeline"
                            name="projectTimeline"
                            placeholder={t('projectTimelinePlaceholder')}
                            value={formik.values.projectTimeline}
                            onChange={formik.handleChange}
                            error={formik.touched.projectTimeline && Boolean(formik.errors.projectTimeline)}
                            helperText={formik.touched.projectTimeline && formik.errors.projectTimeline}
                        />
                    </Box>
                </Box>
                <Box mb={2}>
                    <Box mb={2}>
                        <Typography variant="h2">6. {t('section6Title')}</Typography>
                    </Box>
                    <Box mb={2}>
                        <InputLabel htmlFor="additionalComments">{t('additionalComments')}</InputLabel>
                    </Box>
                    <Box mb={2}>
                        <OutlinedInput
                            id="additionalComments"
                            name="additionalComments"
                            multiline
                            rows={3}
                            value={formik.values.additionalComments}
                            onChange={formik.handleChange}
                            style={{ width: '100%' }}
                        />
                    </Box>
                </Box>
                <Box mb={4}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={isAgreed}
                                onChange={handleAgreementChange}
                                name="isAgreed"
                                color="primary"
                            />
                        }
                        label={t('agreementLabel')}
                        sx={{ '& .MuiTypography-root': { fontSize: 9 } }}
                    />
                    {formik.touched.isAgreed && formik.errors.isAgreed && (
                        <Typography color="error">{formik.errors.isAgreed}</Typography>
                    )}
                </Box>
                {!isSubmitted ? (
                    <Box mb={15}>
                        <Button color="primary" variant="contained" fullWidth type="submit">
                            {t('submit')}
                        </Button>
                    </Box>
                ) : (
                    <Box mb={15}>
                        <Typography variant="h6" color="primary">
                            {t('formSubmittedMessage')} {/* Replace with your translation key */}
                        </Typography>
                        <Typography variant="h6" color="primary">
                            <Link href={"https://www.instagram.com/red_train_production/"}>@red_train_production</Link>
                        </Typography>
                    </Box>
                )}
            </form>
        </Container>
    );
};

export default VideoBriefForm;

