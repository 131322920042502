import React from 'react';
import VideoBriefForm from './components/VideoBriefForm';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

const darkTheme = createTheme({
    typography: {
        fontFamily: [
            'Montserrat',
            'sans-serif',
        ].join(','),
        fontWeightBold: 700,
        h1: {
            fontWeight: 'bold',
            fontSize: '3rem',
        },
        h2: {
            fontWeight: 'bold',
            fontSize: '1.5rem',
        },
    },
    palette: {
        mode: 'dark',
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    fontWeight: 'bold',
                },
            },
        },
        MuiInputBase: {
            styleOverrides: {
                input: {
                    '&::placeholder': {
                        color: '#666',
                    },
                },
            },
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    whiteSpace: 'break-spaces'
                }
            }
        }
    },
});

function App() {
    return (
        <ThemeProvider theme={darkTheme}>
            <CssBaseline />
            <VideoBriefForm />
        </ThemeProvider>
    );
}

export default App;
